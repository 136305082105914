import { Link as RemixLink } from "@remix-run/react";

import { cn } from "~/lib/utils";

interface LinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  isExternal?: boolean;
  openInNewTab?: boolean;
}

export function Link({
  href,
  children,
  className,
  isExternal = false,
  openInNewTab = false,
}: LinkProps) {
  if (isExternal || openInNewTab) {
    return (
      <a
        href={href}
        className={cn(
          "text-gray-700 hover:text-primary hover:underline",
          className
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <RemixLink
      to={href}
      className={cn("text-gray-700 hover:text-primary no-underline", className)}
    >
      {children}
    </RemixLink>
  );
}
